import { Dialog } from '@angular/cdk/dialog';
import { Injectable, inject, Injector, type Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { endOfDay, isAfter } from 'date-fns';
import { BehaviorSubject, type Observable } from 'rxjs';

import { ConfigService } from './config.service';
import { HttpService } from './http.service';
import { LocalizationService } from './localization.service';
import { ModalService } from './modal.service';
import { PermissionService, type UserPermissions } from './permission.service';
import { LocalStorageService } from './persistance.service';
import { SplitService } from './split.service';
import { LS_AUTH_DATA_KEY } from '../constants/ls-auth-data-key';
import { PAGE_URL } from '../constants/page-url';
import { type AuthData } from '../models/authData';
import { type Company } from '../models/company';
import { type LoginData } from '../models/loginData';
import { RegistrationStatuses, type ConnectedUser, type User } from '../models/user';

const IMPERSONATE_BACKUP_KEY = 'impersonateBackup';
const MAIN_USER_DATA = 'mainUserData';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly router = inject(Router);
  private readonly lsService = inject(LocalStorageService);
  private readonly http = inject(HttpService);
  private readonly splitService = inject(SplitService);
  private readonly permissionService = inject(PermissionService);
  private readonly modalService = inject(ModalService);
  private readonly localizationService = inject(LocalizationService);
  private readonly dialog = inject(Dialog);
  private readonly injector = inject(Injector);

  public isAuthenticated$ = new BehaviorSubject<boolean>(null);
  public profile$ = new BehaviorSubject<User>(null);
  public roles$ = new BehaviorSubject<string[]>(null);

  public permissions: UserPermissions = null;
  public userCompany$ = new BehaviorSubject<Company>(null);

  public lastRedirectedRoute: string;
  public isTimedOut: boolean;
  public isSwitchedProfile = false;
  public mainUserData: {
    connectedUsers: ConnectedUser[];
    company: Company;
    userProfile: User;
  };

  public connectedUsers: ConnectedUser[] = [];
  useSplit = false;

  constructor() {
    this.useSplit = ConfigService?.settings?.enableSplit;
  }

  private _isImpersonating = false;

  public get isImpersonating(): boolean {
    return this._isImpersonating && !this.isSwitchedProfile;
  }

  public get isAuthenticated(): boolean {
    return this.isAuthenticated$.value;
  }

  public get userProfile(): User {
    return this.profile$.value;
  }

  get userProfile$(): Signal<User> {
    return toSignal(this.profile$, { injector: this.injector });
  }

  get userCompany(): Company {
    return this.userCompany$.value;
  }

  set userCompany(company: Company) {
    this.userCompany$.next(company);
  }

  get company$(): Signal<Company> {
    return toSignal(this.userCompany$, { injector: this.injector });
  }

  public tryLoginWithSavedToken(): void {
    let savedToken: any;
    try {
      savedToken = this.lsService.get(LS_AUTH_DATA_KEY);
    } catch (error) {
      console.log(error);
      this.lsService.delete(LS_AUTH_DATA_KEY);
    }

    if (savedToken && isAfter(endOfDay(new Date(savedToken.expires)), new Date())) {
      this.login({ ...savedToken }, true);
    } else {
      this.isAuthenticated$.next(false);
    }
  }

  public login(tokenData: LoginData, isAutoLogin?: boolean): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        this.initializeLogin(tokenData);

        if (this._isImpersonating) {
          this.handleImpersonatingUser(tokenData)
            .then(() => {
              this.finalizeLogin(isAutoLogin);
              resolve();
            })
            .catch(reject);
          return;
        }

        this.parseLoginData()
          .then(() => {
            this.finalizeLogin(isAutoLogin);
            resolve();
          })
          .catch((error) => {
            this.isAuthenticated$.next(false);
            reject(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  public async updateProfile(profile: User): Promise<any> {
    const promises: Array<Promise<any>> = [this.permissionService.getPermissions()];

    if (profile.companyId) {
      promises.push(this.http.get(`api/companies/${profile.companyId}`));
    }

    const res = await Promise.all(promises);
    this.permissions = res[0];
    this.userCompany$.next(res[1]);

    if (this.permissions.UserCompanyAccessRequest_Authenticate) {
      const users = await this.loadConnectedUsers();
      this.connectedUsers = users;
      this.profile$.next(profile);
    } else {
      this.connectedUsers = [];
      this.profile$.next(profile);
    }
  }

  public redirectAfterLogin(): void {
    const user = this.profile$.value;

    // // TODO: think about the separete component for company confirmation
    if (
      user.registrationStatus === RegistrationStatuses.companySelection ||
      user.registrationStatus === RegistrationStatuses.brokerageCompanySelection ||
      user.registrationStatus === RegistrationStatuses.companyConfirmation
    ) {
      this.router.navigate([PAGE_URL.COMPANY_SELECTION]);
      return;
    }

    if (
      user.registrationStatus === RegistrationStatuses.companyAssosiated ||
      user.registrationStatus === RegistrationStatuses.businessCompanyAssociated
    ) {
      this.router.navigate([PAGE_URL.PROFILE_SETUP]);
      return;
    }

    if (this.lastRedirectedRoute) {
      this.router.navigateByUrl(this.lastRedirectedRoute);
    } else {
      this.router.navigate(['/']);
    }
  }

  public logout(): void {
    if (this.useSplit) {
      this.splitService.destroyClient();
    }
    this.modalService.closeModals();
    this.lsService.delete(LS_AUTH_DATA_KEY);
    this.lsService.delete(MAIN_USER_DATA);
    this.lsService.delete(IMPERSONATE_BACKUP_KEY);
    this._isImpersonating = false;
    this.isSwitchedProfile = false;
    this.isAuthenticated$.next(false);
    this.dialog.closeAll();
    this.router.navigate(['login']);
  }

  public searchUsers(searchString: string, limit: number = 25, offset: number = 0): Observable<any> {
    return this.http.getV2(`api/users/search?startsWith=${searchString}&limit=${limit}&offset=${offset}`);
  }

  public async getUserInfo(id: number): Promise<User> {
    return await this.http.get(`api/users?id=${id}`);
  }

  public async impersonate(user: User): Promise<any> {
    await this.http.post('api/auth/impersonate', { userId: user.id }).then((tokenData) => {
      if (this.useSplit) {
        this.splitService.initClient(user);
      }

      this.lsService.set(IMPERSONATE_BACKUP_KEY, this.lsService.get(LS_AUTH_DATA_KEY));
      this.lsService.set(LS_AUTH_DATA_KEY, tokenData);

      if (this.useSplit) {
        this.splitService.initClient(tokenData.userInfo);
      }
      this.updateProfile(tokenData.userInfo).then(() => {
        this._isImpersonating = true;
        this.isAuthenticated$.next(true);
        this.redirectAfterLogin();
      });
    });
  }

  public switchProfile(user: ConnectedUser, redirectTo?: string): void {
    if (this.isSwitchedProfile) {
      this.lsService.set(LS_AUTH_DATA_KEY, this.lsService.get(IMPERSONATE_BACKUP_KEY));
    }

    this.http.post('api/companyAccesses/token', { connectedUserId: user.userId }).then((tokenData) => {
      if (!this.isSwitchedProfile) {
        const mainUserData = this.lsService.get(LS_AUTH_DATA_KEY);
        this.lsService.set(IMPERSONATE_BACKUP_KEY, mainUserData);
        this.lsService.set(MAIN_USER_DATA, {
          connectedUsers: [...this.connectedUsers],
          company: { ...this.userCompany },
          userProfile: { ...this.userProfile },
        });
      }

      this.lsService.set(LS_AUTH_DATA_KEY, tokenData);

      const dashboardUrl = window.location.origin + `/dashboard-old`;
      if (redirectTo) {
        window.location.href = redirectTo;
      } else if (window.location.href === dashboardUrl) {
        window.location.reload();
      } else {
        window.location.href = dashboardUrl;
      }
    });
  }

  public exitImpersonation(): void {
    const tokenData = this.lsService.get(IMPERSONATE_BACKUP_KEY);
    if (this.useSplit) {
      this.splitService.initClient(tokenData.userInfo);
    }

    this.lsService.set(LS_AUTH_DATA_KEY, tokenData);
    this.lsService.delete(IMPERSONATE_BACKUP_KEY);

    if (this.isSwitchedProfile) {
      this.lsService.delete(MAIN_USER_DATA);
    }

    const dashboardUrl = window.location.origin + `/dashboard`;
    if (window.location.href === dashboardUrl) {
      window.location.reload();
    } else {
      window.location.href = dashboardUrl;
    }
  }

  public async refreshAuthToken(): Promise<AuthData> {
    const tokenData = this.lsService.get(LS_AUTH_DATA_KEY);
    return await new Promise((resolve, reject) => {
      this.http
        .post('/api/auth/refresh', {
          accessToken: tokenData.token,
          refreshToken: tokenData.refreshToken,
        })
        .then((res) => {
          this.lsService.set(LS_AUTH_DATA_KEY, res);
          this.parseLoginData()
            .then(() => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((res) => {
          if (res.error.errorCode === 400 && this.isSwitchedProfile) {
            this.exitImpersonation();
          }
          reject(res);
        });
    });
  }

  private initializeLogin(tokenData: LoginData): void {
    this.lsService.set(LS_AUTH_DATA_KEY, tokenData);
    const backup = this.lsService.get(IMPERSONATE_BACKUP_KEY);
    this._isImpersonating = !!backup;

    this.mainUserData = this.lsService.get(MAIN_USER_DATA);
    this.isSwitchedProfile = !!this.mainUserData;
  }

  private handleImpersonatingUser(tokenData: LoginData): Promise<void> {
    if (this.useSplit) {
      this.splitService.initClient(tokenData.userInfo);
    }
    return this.parseLoginData().then(() => {
      this.isAuthenticated$.next(true);
    });
  }

  private finalizeLogin(isAutoLogin?: boolean): void {
    this.isAuthenticated$.next(true);
    this.isTimedOut = false;
    if (!isAutoLogin) {
      this.redirectAfterLogin();
    }
  }

  private parseLoginData(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getProfile()
        .then((user) => {
          this.localizationService.init(user.internationalSettings);

          if (this.useSplit) {
            this.splitService.initClient(user);
          }

          this.updateProfile(user)
            .then(() => {
              resolve(null);
            })
            .catch((err) => reject(err));
        })
        .catch((err) => reject(err));
    });
  }

  private getProfile(): Promise<User> {
    return this.http.get(`api/profile`);
  }

  private loadConnectedUsers(): Promise<ConnectedUser[]> {
    return this.http.get(`/api/companyAccesses/connectedUsers`);
  }
}
