import { type CompanyLocation } from '@core/models/location';

import { type InternationalSettings } from './internationalSettings';

export interface User {
  id: number;
  username?: string;
  companyId?: number;
  suggestedCompanyId?: string;
  companyName?: string;
  email?: string;
  twoFactorEnabled?: boolean;
  termOfUseAcceptDate?: string;
  registrationStatus?: RegistrationStatuses;
  logoUrl?: string;
  logoSettings?: {
    fullSizeLogoUrl: string;
    modifiedAt?: string;
    settings: string;
  };
  title?: string;
  isBillingAdministrator?: boolean;
  isWorkEmailConfirmed?: boolean;
  isPhoneNumberConfirmed?: boolean;
  isPersonalEmailConfirmed?: boolean;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  roles?: UserRole[];
  phoneNumber?: string;
  workPhoneNumber?: string;
  workPhoneNumberExtension?: string;
  hasFaxNumber?: boolean;
  faxNumber?: string;
  hasProvidedPassword?: string;
  communicationRoles?: string[];
  status?: UserStatus;
  lastActive?: string;
  primaryLocation?: CompanyLocation;
  companyLicenseTier?: string;
  isAbleToAcceptInvitations?: boolean;
  internationalSettings: InternationalSettings;
  workEmail?: string;
  userAuthType?: UserAuthType;
}

export interface UserInfo {
  id: number;
  status: UserStatus;
  firstName: string;
  lastName: string;
  email?: string;
  title: string;
  phoneNumber: string;
  workPhoneNumber: string;
  workPhoneNumberExtension: string;
  hasFaxNumber?: boolean;
  faxNumber?: string;
  role: UserRole;
  communicationRoleIds: string[];
  lastActivityAt: string;
  logoUrl?: string;
  logoSettings?: {
    fullSizeLogoUrl: string;
    modifiedAt?: string;
    settings: string;
  };
  fullName?: string;
  isOnline?: boolean;
  isFavorite?: boolean;
  locationId?: number;
  isAbleToAcceptInvitations: boolean;
  isMasterEmailAccount: boolean;

  [x: string]: any;
}

export interface ConnectedUser {
  userId: number;
  companyId: number;
  companyName: string;
  companyLogoUrl: string;
}

export enum RegistrationStatuses {
  registered = 'Registered',
  companySelection = 'CompanySelection',
  brokerageCompanySelection = 'BrokerageCompanySelection',
  companyTypeSelection = 'CompanyTypeSelection',
  companyConfirmation = 'CompanyConfirmation',
  completed = 'Completed',
  companyAssosiated = 'CompanyAssociated',
  businessCompanyAssociated = 'BusinessCompanyAssociated',
}

export enum UserAuthType {
  General = 'general',
  Impersonation = 'impersonation',
  Delegated = 'delegated',
  SSO = 'sso',
  ExternalApi = 'externalApi',
}

export enum UserStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Invited = 'Invited',
}

export enum UserRole {
  companyUser = 'CompanyUser',
  companyViewer = 'CompanyViewer',
  companyAdmin = 'CompanyAdmin',
  companyMasterDataManager = 'CompanyMasterDataManager',
  dataCurator = 'LedgersetDataCurator',
  support = 'LedgersetSupportRepresentative',
  systemAdministrator = 'LedgersetSystemAdministrator',
}

export interface UploadedImageData {
  logoImageId: string;
  fullSizeLogoImageId: string;
  logoSettings: string;
  logoUrl?: string;
  fullSizeLogoUrl?: string;
}
